import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from './app.service';


/**
 * SETUP
 *
 * follow these steps to set up DataGuard in any component
 * 1: add DataGuardService to the component's providers
 * 		this prevents DataGuard from persisting as a singleton after the component is destroyed
 * 2: Add DataGuard to the component's cosntructor
 * 3: Add "canDeactivate: [DataGuardService]" to the component's route
 * 4: Add canDeactivate() to the component - A basic template is in the comments below
 * 5: Add a title and message to dataGuard.confirm()
 * 6: Ensure all variables pertaining to "dirty data" point to dataGuard.dataDirty
 * 7: Ensure all instances where you check for dirty data uses dataGuard.getDirty()
 * 8: Instruct the component to reset dataGuard.dataDirty to false when saving
 * 9: Add dataGuard.reset() to the component's ngOnDestroy method
 */

/**
 * for ui-modal views use modal.dataGuard instead of injecting a new instance of the service
 */

/**
 * canDeactivate(): Promise<boolean> | boolean {
		if (this.dataGuard.dataDirty) return this.dataGuard.confirm();
		else return true;
	}
 */


export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
	providedIn: 'root'
})
export class DataGuardService implements OnDestroy, CanDeactivate<CanComponentDeactivate> {

	noPrompt = false; // set to true to disable the confirmation prompts
	dataDirty = false;
	useInternalNavigationPrompt = false;

	constructor(private router: Router, private app: AppService) {
		window.addEventListener('beforeunload', e => this.shouldUnload(e));
	}

	ngOnDestroy(): void {
		window.removeEventListener('beforeunload', e => this.shouldUnload(e));
	}

	canDeactivate(
		component: CanComponentDeactivate,
		_currentRoute: ActivatedRouteSnapshot,
		_currentState: RouterStateSnapshot,
		_nextState?: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

		return this.noPrompt ? true : component.canDeactivate();
	}

	confirm(title?: string, message?: string): Promise<boolean> {
		if (!title) title = 'Are you sure you want to leave?';
		if (!message) message = 'Unsaved changes will be lost';
		let prompt = null;
		if (this.getDirty() && !this.noPrompt) {
			if (this.useInternalNavigationPrompt) {
				prompt = confirm('Changes that you made may not be saved');
			} else {
				prompt = new Promise((resolve, _reject) => {
					this.app.dialog.popFixed(title, message, ['Cancel', '*Leave'], button => {
						resolve(button === 1);
					});
				});
			}
		} else prompt = new Promise((resolve, _reject) => {
			resolve(true);
		});

		return this.noPrompt ? true : prompt;
	}

	shouldUnload(e) {
		if (!this.getDirty() || this.noPrompt) return true;
		const confirmationMessage = 'It looks like you have been editing something. If you leave before saving, your changes will be lost.';

		if (e) e.returnValue = confirmationMessage; // Gecko + IE
		return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
	}

	getDirty() {
		return this.dataDirty;
	}

	reset() {
		this.noPrompt = false;
		this.dataDirty = false;
		this.useInternalNavigationPrompt = false;
	}
}
