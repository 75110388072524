<nav *ngIf="pagination" class="d-flex align-items-center">
	<div class="btn-group">
		<button class="btn btn-outline-secondary btn-sm" [ngClass]="{ disabled: pagination.page === 1 }" (click)="pagination.prevPage()"><i class="wq wq-angle-left"></i></button>
		<ng-container *ngFor="let page of pagination.getTabs()">
			<button *ngIf="page !== null" class="btn btn-sm" [ngClass]="{ 'btn-outline-secondary': page !== pagination.page, 'btn-primary': page === pagination.page }" (click)="pagination.page = page">{{page}}</button>
			<button *ngIf="page === null" class="btn btn-secondary btn-sm disabled">...</button>
		</ng-container>
		<button class="btn btn-outline-secondary btn-sm" [ngClass]="{ disabled: pagination.page === pagination.pageCount }" (click)="pagination.nextPage()"><i class="wq wq-angle-right"></i></button>
	</div>

	<span class="counter text-secondary flex-weight-1 ml-3">{{pagination.count}} {{pagination.count === 1 ? 'item' : 'items'}}</span>

	<div class="btn-group">
		<ng-container *ngFor="let size of pagination.pageSizeList">
			<button class="btn btn-sm" [ngClass]="{ 'btn-outline-secondary': size !== pagination.pageSize, 'btn-primary': size === pagination.pageSize }" (click)="pagination.pageSize = size">{{size}}</button>
		</ng-container>
	</div>
</nav>
