import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-customer-contact',
	templateUrl: './modal-edit-customer-contact.component.html'
})
export class ModalEditCustomerContactComponent extends UIModalBaseComponent {

	data;

	constructor(
		private app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.data = Mangler.clone(mdata);
		this.modal.open();
	}

	saveButton() {
		if (!this.data.contact_first_name && !this.data.contact_surname) {
			this.app.notifications.showDanger('Please enter contact name.');
			return;
		}
		this.modal.close(this.data);
	}

}
