import { Component, OnChanges, Input } from '@angular/core';
import { UIDataService } from './ui-data.service';

@Component({
	selector: 'ui-form',
	template:
	`<div [ngClass]="{ 'form-horizontal': direction === 'horizontal', 'form-inline': direction === 'inline' }">
		<ng-content></ng-content>
	</div>`,
	providers: [UIDataService]
})
export class UIFormComponent implements OnChanges {

	@Input() labelWidth;
	@Input() direction = 'horizontal';

	constructor(private data: UIDataService) { }

	ngOnChanges() {
		this.data.formLabelWidth = this.labelWidth;
		this.data.formDirection = this.direction;
	}

}
