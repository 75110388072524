<form>
	<ui-modal modalTitle="Contact Details">
		<ng-container *ngIf="data">
			<ui-form labelWidth="10rem">
				<ui-form-control label="First Name">
					<input class="form-control" [(ngModel)]="data.contact_first_name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>
				<ui-form-control label="Surname">
					<input class="form-control" [(ngModel)]="data.contact_surname" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Position">
					<input class="form-control" [(ngModel)]="data.contact_position" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Email Address">
					<input class="form-control" [(ngModel)]="data.contact_email" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Mobile Number">
					<input class="form-control" [(ngModel)]="data.contact_mobile" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<hr>
				<ui-form-control label="Address">
					<input class="form-control" [(ngModel)]="data.address_line_1" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="data.address_line_2" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="data.address_line_3" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Town">
					<input class="form-control" [(ngModel)]="data.posttown" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="County">
					<input class="form-control" [(ngModel)]="data.county" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Postcode">
					<input class="form-control w-50" [(ngModel)]="data.postcode" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Country">
					<input class="form-control" [(ngModel)]="data.country" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
			</ui-form>
		</ng-container>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" ui-modal-footer>Save</button>
	</ui-modal>
</form>
