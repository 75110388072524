import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'app-core-feedback-modal',
	templateUrl: './core-feedback-modal.component.html'
})
export class CoreFeedbackModalComponent extends UIModalBaseComponent {

	message = '';
	disabled = false;

	constructor(
		private api: ApiService,
		private app: AppService
	) {
		super();
	}

	open(_data: any) {
		this.modal.open();
	}

	reportBug() {
		this.disabled = true;
		this.api.organisation.reportBug(this.message, '' + window.location, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('Thank you for your report', 'Your message has been sent.');
			this.message = '';
			this.modal.close();
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

}
