import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'ui-grid-row',
	template: `<ng-content></ng-content>`
})
export class UIGridRowComponent implements OnInit {

	@Input() weight: number = 1;
	@Input() spaced: boolean = false;

	@Input('weight-0') weight0;
	@Input('weight-1') weight1;
	@Input('weight-2') weight2;
	@Input('weight-3') weight3;
	@Input('weight-4') weight4;
	@Input('weight-5') weight5;
	@Input('weight-6') weight6;
	@Input('weight-7') weight7;
	@Input('weight-8') weight8;
	@Input('weight-9') weight9;
	@Input('weight-10') weight10;
	@Input('weight-11') weight11;
	@Input('weight-12') weight12;

	@HostBinding('class') cls = 'd-flex flex-row';
	@HostBinding('class.flex-weight-1') get hasWeight1() { return this.weight === 1; }
	@HostBinding('class.flex-weight-2') get hasWeight2() { return this.weight === 2; }
	@HostBinding('class.flex-weight-3') get hasWeight3() { return this.weight === 3; }
	@HostBinding('class.flex-weight-4') get hasWeight4() { return this.weight === 4; }
	@HostBinding('class.flex-weight-5') get hasWeight5() { return this.weight === 5; }
	@HostBinding('class.flex-weight-6') get hasWeight6() { return this.weight === 6; }
	@HostBinding('class.flex-weight-7') get hasWeight7() { return this.weight === 7; }
	@HostBinding('class.flex-weight-8') get hasWeight8() { return this.weight === 8; }
	@HostBinding('class.flex-weight-9') get hasWeight9() { return this.weight === 9; }
	@HostBinding('class.flex-weight-10') get hasWeight10() { return this.weight === 10; }
	@HostBinding('class.flex-weight-11') get hasWeight11() { return this.weight === 11; }
	@HostBinding('class.flex-weight-12') get hasWeight12() { return this.weight === 12; }

	@HostBinding('class.flex-spaced') get isSpaced() { return this.spaced; }

	ngOnInit(): void {
		if (this.weight0 === '') this.weight = 0;
		if (this.weight1 === '') this.weight = 1;
		if (this.weight2 === '') this.weight = 2;
		if (this.weight3 === '') this.weight = 3;
		if (this.weight4 === '') this.weight = 4;
		if (this.weight5 === '') this.weight = 5;
		if (this.weight6 === '') this.weight = 6;
		if (this.weight7 === '') this.weight = 7;
		if (this.weight8 === '') this.weight = 8;
		if (this.weight9 === '') this.weight = 9;
		if (this.weight10 === '') this.weight = 10;
		if (this.weight11 === '') this.weight = 11;
		if (this.weight12 === '') this.weight = 12;
		if (this.spaced as any === '') this.spaced = true;
	}

}
