import { Directive, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AppService } from 'app/app.service';
import { Subscription } from 'rxjs';

declare const Mangler: any;

@Directive({
	selector: '[appTutorial]'
})
export class TutorialDirective implements OnInit, OnDestroy {

	@Input() appTutorial;

	@HostBinding('class') className = '';

	private sub: Subscription;

	constructor(
		public app: AppService
	) { }

	ngOnInit() {
		// Only start listener if there is anything passed
		// appTutorial must be a constant value, cannot change after init
		if (this.appTutorial) {
			this.sub = this.app.onTutorialStepChange.subscribe(stepId => {
				this.refresh(stepId);
			});

			const activeStepId = this.app.tutorial?.tutorial?.activeStep?.id;
			this.refresh(activeStepId);
		}
	}

	ngOnDestroy() {
		if (this.sub) this.sub.unsubscribe();
	}

	refresh(stepId) {
		if (!stepId) {
			this.className = '';
			return;
		}

		if (Mangler.isArray(this.appTutorial)) {
			this.className = this.appTutorial.indexOf(stepId) !== -1 ? 'tutorial-highlight' : '';
		} else {
			this.className = this.appTutorial === stepId ? 'tutorial-highlight' : '';
		}
	}

}
