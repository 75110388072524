import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'ui-card',
	template:
		`<div *ngIf="header" class="card-body" style="flex-grow: 0 !important; flex-shrink: 0 !important;">
			<div class="d-flex align-items-center">
				<div class="flex-weight-1" style="padding-top: 0.35rem; padding-bottom: 0.35rem;">
					<h2 class="m-0">{{header}} <span *ngIf="headerBadge" class="badge badge-{{headerBadgeClass}} ml-2 px-3">{{headerBadge}}</span></h2>
				</div>
				<div>
					<ng-content select="[ui-card-header]"></ng-content>
				</div>
			</div>
			<hr *ngIf="headerDivider && !collapse" style="margin: 1.25rem 0 -1.25rem 0;">
		</div>
		<div *ngIf="customHeader && wait" class="card-body" style="flex-grow: 0 !important; flex-shrink: 0 !important;">
			<ng-content select="[ui-card-custom-header]"></ng-content>
			<hr *ngIf="headerDivider && customHeaderDivider" style="margin: 1.25rem 0 -1.25rem 0;">
		</div>
		<div *ngIf="!collapse" class="card-body flex-weight-1" [style.overflow]="scrollable && !overflowVisible ? 'auto' : 'visible'" [ngClass]="{ 'p-0': !padding }">
			<ng-container *ngIf="wait">
				<ng-content></ng-content>
			</ng-container>
			<ui-wait *ngIf="!wait"></ui-wait>
		</div>
		<div *ngIf="wait && footer && !collapse" class="card-body" style="flex-grow: 0 !important; flex-shrink: 0 !important;">
			<hr *ngIf="footerDivider" style="margin: -1.25rem 0 1.25rem 0;">
			<ng-content select="[ui-card-footer]"></ng-content>
		</div>`,
	styles: ['.overflow-hidden { overflow: hidden; } .card-body { position: relative; }']
})
export class UICardComponent implements OnInit {

	@Input() widget: boolean = false;
	@Input() wait: any = true;
	@Input() header: string;
	@Input() headerBadge: string;
	@Input() headerBadgeClass = 'secondary';
	@Input() headerDivider = true;
	@Input() customHeader = false;
	@Input() customHeaderDivider = true;
	@Input() footer: boolean;
	@Input() footerDivider = false;
	@Input() collapse: boolean = false;
	@Input() scrollable: boolean = true;
	@Input() overflowVisible: boolean = false;
	@Input() padding: boolean = true;

	@HostBinding('class') cls = 'card shadow d-flex flex-column';
	@HostBinding('class.fill') get isWidget() { return this.widget; }
	@HostBinding('class.overflow-hidden') get isOverflowHidden() { return !this.overflowVisible; }

	ngOnInit(): void {
		if (this.widget as any === '') this.widget = true;
	}

}
