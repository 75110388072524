import { ViewChild, Output, EventEmitter, AfterViewInit, Component } from '@angular/core';
import { UIModalComponent } from './ui-modal.component';

@Component({ template: '' })
export abstract class UIModalBaseComponent implements AfterViewInit {

	@ViewChild(UIModalComponent) modal: UIModalComponent;
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() close = new EventEmitter<any>();

	constructor() { }

	ngAfterViewInit() {
		this.modal.onClose = this.close;
	}

	abstract open(data: any);

}
