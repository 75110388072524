<ui-modal [header]="false" [size]="size" [footer]="!wait">
	<h3 *ngIf="title">{{title}}</h3>
	<hr *ngIf="title && message">

	<ng-container *ngIf="!wait">
		<p *ngIf="message && !html">{{message}}</p>
		<div *ngIf="message && html" class="scrollable" style="max-height: 600px;" [innerHtml]="message | safeHtml"></div>
	</ng-container>

	<ng-container *ngIf="wait">
		<div class="d-flex align-items-center pt-2 pb-1">
			<ui-wait></ui-wait>
			<div class="ml-3">
				<div *ngIf="message && !html" class="text-large">{{message}}</div>
				<div *ngIf="message && html" class="scrollable" style="max-height: 600px;" [innerHtml]="message | safeHtml"></div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="progressShow && modal.isOpen">
		<div class="progress mt-3">
			<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style.width.%]="progressMax > 0 ? ((progressValue / progressMax) * 100) : 0"><span *ngIf="(progressMax > 0 ? ((progressValue / progressMax) * 100) : 0) >= 5">{{(progressMax > 0 ? ((progressValue / progressMax) * 100) : 0) | decimal:0:0}}%</span></div>
		</div>
		<div class="text-secondary mt-1">{{progressText}}</div>
	</ng-container>

	<div class="d-flex" ui-modal-footer>
		<ng-container *ngFor="let btn of buttons">
			<button *ngIf="btn.cls !== 'btn-primary'" class="btn mr-2 {{btn.cls}}" (click)="buttonClick(btn)">{{btn.name}}</button>
			<button *ngIf="btn.cls === 'btn-primary'" class="btn mr-2 {{btn.cls}}" (click)="buttonClick(btn)" appAutoFocus>{{btn.name}}</button>
		</ng-container>
	</div>
</ui-modal>
