import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AppService } from './app.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

	constructor(
		private app: AppService,
		private api: ApiService,
		private router: Router,
		private deviceService: DeviceDetectorService
	) { }

	canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		return new Promise((resolve, _reject) => {
			this.app.org = route.params.org || null;

			this.api.refreshUserInfo(() => {
				// Check if user is logged in
				if (!this.app.userEmail) {
					resolve(this.router.parseUrl('/'));
					return;
				}

				// Check if user has access to the account
				if (!this.app.orgInfo) {
					resolve(this.router.parseUrl('/'));
					return;
				}

				// Check if sandbox has expired
				if (!route.data?.authGuardSkipExpired) {
					if (this.app.orgInfo.is_sandbox && !this.app.orgInfo.time_left) {
						resolve(this.router.parseUrl('/' + this.app.org + '/account/sandbox-expired'));
						return;
					}
				}

				// Check if mobile device
				if (!this.app.shouldSkipDeviceCheck) {
					if (this.deviceService.isMobile()) {
						resolve(this.router.parseUrl('/mobile'));
						return;
					}
				}

				// Check if route is root only
				if (route.data?.authGuardRootOnly) {
					if (!this.app.orgInfo.role.is_root) {
						resolve(this.router.parseUrl('/' + this.app.org));
						return;
					}
				}

				// If payment is overdue, redirect to billing page
				if (route.data && !route.data.billing) {
					// Ignore payment overdue flag for root users
					if (this.app.orgInfo.payment_overdue && !this.app.orgInfo.role.is_root) {
						if (this.app.orgInfo.role.manage_billing) {
							// Got access to billing, redirect to payment page
							resolve(this.router.parseUrl('/' + this.app.orgInfo.alias + '/settings/billing'));
						} else {
							// Don't have access to billing
							resolve(this.router.parseUrl('/' + this.app.orgInfo.alias + '/settings'));
						}
					}
				}

				resolve(true);
			});
		});
	}

	canLoad(_route: Route, _segments: UrlSegment[]): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
		// TODO: Handle broken routes better.

		// When the application is updated, lazy loded module files may no longer be there. This
		// results in a ChunkLoadError being thrown in the background, without triggering the
		// canActivate method of this route guard. That means we miss the chance to alert the
		// user that there is a new version, and navigation fails silently.

		// With a canLoad guard that runs the first time, we just run a useless server hit that
		// will resolve the new build number. This way we have a chance to alert the user before
		// the error gets thrown.

		this.api.general.ping();
		return true;
	}

}
