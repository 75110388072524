import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AppService } from 'app/app.service';

@Component({
	selector: 'ui-alert',
	template:
		`<div class="alert alert-{{color}} bg-{{color}} border-{{color}} text-white d-flex align-items-center">
			<div class="mr-3"><i class="{{icon}} xl" style="line-height: 1.1;"></i></div>
			<div class="flex-weight-1">
				<ng-content></ng-content>
			</div>
			<div *ngIf="!hidden && dismissable" class="ml-3">
				<button type="button" class="btn btn-sm btn-outline-light" (click)="dismiss()"><i class="wq wq-check mr-2"></i>Got it</button>
			</div>
		</div>`
})
export class UIAlertComponent implements OnInit {

	@Input() flag;
	@Input() color = 'purple';
	@Input() icon = 'wq wq-info';
	@Input() dismissable = true;

	@HostBinding('class.d-none') hidden = false;

	constructor(public app: AppService) { }

	ngOnInit() {
		if (this.flag) this.hidden = this.app.getLocalFlag(this.flag);
	}

	dismiss() {
		this.hidden = true;
		if (this.flag) this.app.setLocalFlag(this.flag, true);
	}

}
