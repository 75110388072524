import { Injectable } from '@angular/core';
import { UITreeData } from './ui-tree.component';

@Injectable({
	providedIn: 'any'
})
export class UIDataService {

	formLabelWidth;
	formDirection;

	treeData: UITreeData;
	treeDragNode: any = null;
	treeDropNode: any = null;
	treeDropPos: string = '';
	treeBlockLeave: boolean = false;
	treeBlockTimer = null;
	treeTargetParent = null;
	treeTargetPosition = -1;
	treeShowActions = false;
}
