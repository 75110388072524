import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'app-tutorial-cancel-modal',
	templateUrl: './tutorial-cancel-modal.component.html'
})
export class TutorialCancelModalComponent extends UIModalBaseComponent {

	disabled = false;

	constructor(
		private app: AppService,
		private api: ApiService
	) {
		super();
	}

	open(_mdata: any = null) {
		this.modal.open();
	}

	cancelTutorial() {
		this.disabled = true;
		this.api.organisation.tutorialStop(() => {
			this.api.refreshUserInfo();
			this.disabled = false;
			this.modal.close();
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

}
