<ui-modal modalTitle="Quote Status" icon="wq wq-info" [footer]="false">

	<div *ngIf="mode === 'send'" class="text-center">
		<p style="padding: 0.6rem 5rem;">To mark your quote as <b>sent</b>, you need to create and download your proposal. Click the button below to open the proposal generator.</p>
		<p style="padding: 0 5rem 0.6rem 5rem;">Once sent, you will be able to set the status to <b>accepted</b>, <b>complete</b> or <b>cancelled</b>, depending on the outcome.</p>
		<button type="button" class="btn btn-primary mb-2" (click)="openProposalGenerator()">Open Proposal Generator</button>
	</div>

	<div *ngIf="mode === 'review'" class="text-center">
		<p style="padding: 0.6rem 5rem;">You need to create a proposal and <b>submit your quote for review</b> from the proposal generator. Click the button below to open the proposal generator.</p>
		<p style="padding: 0 5rem 0.6rem 5rem;">Once approved, you will be able to set the status to <b>sent</b>.</p>
		<button type="button" class="btn btn-primary mb-2" (click)="openProposalGenerator()">Open Proposal Generator</button>
	</div>

</ui-modal>
