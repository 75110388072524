import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'app-core-quote-review-result-modal',
	templateUrl: './core-quote-review-result-modal.component.html'
})
export class CoreQuoteReviewResultModalComponent extends UIModalBaseComponent {

	quoteId;
	newStage = '';
	notes = '';
	disabled = false;

	record = {
		id: 0,
		quote_date: null,
		expiry_date: null
	};

	constructor(
		private app: AppService,
		private api: ApiService
	) {
		super();
	}

	open(mdata: any) {
		this.quoteId = mdata.id;
		this.newStage = '';
		this.notes = '';
		this.modal.open();
	}

	submitResult() {
		this.disabled = true;

		this.api.quote.submitReviewResult({
			id: this.quoteId,
			stage: this.newStage,
			notes: this.notes
		}, () => {
			this.disabled = false;
			this.app.quotePriceChange.emit();
			this.app.quoteStageChange.emit();
			this.modal.close(true);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});

	}

}
