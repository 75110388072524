import { Component } from '@angular/core';

declare const Mangler: any;

export class NotificationItem {

	static STYLE_PRIMARY = 'primary';
	static STYLE_DANGER = 'danger';
	static STYLE_WARNING = 'warning';
	static STYLE_SUCCESS = 'success';
	static STYLE_INFO = 'info';

	static LIFETIME_SHORT = 2000;
	static LIFETIME_LONG = 5000;
	static LIFETIME_INFINITE = 0;

	private timer;
	public shown = false;

	constructor(
		private component: NotificationsComponent,
		public style,
		public title,
		public message,
		public lifetime = NotificationItem.LIFETIME_SHORT,
		public action: NotificationAction = null
	) {
		this.timer = setTimeout(() => {
			this.shown = true;
			if (lifetime > 0) {
				this.timer = setTimeout(() => {
					this.dismiss();
				}, lifetime);
			}
		}, 100);
	}

	public dismiss() {
		clearTimeout(this.timer);
		this.shown = false;

		setTimeout(() => {
			const i = this.component.list.indexOf(this);
			if (i !== -1) {
				this.component.list.splice(i, 1);
				this.component.list = this.component.list.slice();
			}
		}, 1000);
	}
}

export class NotificationAction {
	constructor(
		public title,
		public callback = null
	) { }
}

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {

	list: NotificationItem[] = [];

	constructor() {
		Mangler.registerType(NotificationItem, { get: 'array' });
	}

	show(style, title, message = '', lifetime = NotificationItem.LIFETIME_SHORT, action: NotificationAction = null) {
		this.list = this.list.concat([new NotificationItem(this, style, title, message, lifetime, action)]);

		const shownItems = Mangler.find(this.list, { shown: true });
		if (shownItems.length > 8) shownItems[0].dismiss();
	}

	showDanger(title, message = '', lifetime = NotificationItem.LIFETIME_LONG, action: NotificationAction = null) { this.show(NotificationItem.STYLE_DANGER, title, message, lifetime, action); }
	showWarning(title, message = '', lifetime = NotificationItem.LIFETIME_LONG, action: NotificationAction = null) { this.show(NotificationItem.STYLE_WARNING, title, message, lifetime, action); }
	showSuccess(title, message = '', lifetime = NotificationItem.LIFETIME_SHORT, action: NotificationAction = null) { this.show(NotificationItem.STYLE_SUCCESS, title, message, lifetime, action); }
	showPrimary(title, message = '', lifetime = NotificationItem.LIFETIME_LONG, action: NotificationAction = null) { this.show(NotificationItem.STYLE_PRIMARY, title, message, lifetime, action); }
	showInfo(title, message = '', lifetime = NotificationItem.LIFETIME_LONG, action: NotificationAction = null) { this.show(NotificationItem.STYLE_INFO, title, message, lifetime, action); }

	itemClick(item: NotificationItem) {
		if (!item.action) item.dismiss();
	}

	itemActionClick(item: NotificationItem) {
		item.action?.callback?.();
	}

}
