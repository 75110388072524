import { Component, Input } from '@angular/core';
import { UIDataService } from './ui-data.service';

@Component({
	selector: 'ui-form-control',
	template:
		`<div class="form-group mb-3" [ngClass]="{ 'd-flex': data.formDirection === 'horizontal', 'inline': data.formDirection === 'inline' }">
			<label class="col-form-label" [ngClass]="{ 'mr-2': data.formDirection === 'inline' }" style="width: {{data.formLabelWidth}};">
				{{label}}
				<ng-container *ngIf="note"><br><span class="text-small text-silver">{{note}}</span></ng-container>
			</label>
			<div class="flex-weight-1">
				<ng-content></ng-content>
			</div>
		</div>`,
	styles: []
})
export class UIFormControlComponent {

	@Input() label;
	@Input() note = '';

	constructor(public data: UIDataService) { }

}
