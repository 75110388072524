<ui-modal modalTitle="Knowledgebase" icon="wq wq-help" [footer]="false">

	<div class="text-center">
		<p style="padding: 0.6rem 5rem;">View our collection of knowledgebase videos to learn more about how to use WeQuote.</p>
		<a href="https://wequote.cloud/knowledgebase/" target="_blank" class="btn btn-primary mb-2">Visit Knowledgebase</a>
	</div>

	<a href="mailto:wequote@wetech.cloud" class="btn btn-medium d-block mx-n4 mb-n4 mt-4 p-3 h-auto" style="border-top-left-radius: 0; border-top-right-radius: 0;">Still having issues? wequote@wetech.cloud</a>

</ui-modal>
