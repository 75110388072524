<ui-grid-col *ngIf="!app.shouldSkipDeviceCheck" spaced>
	<ui-grid-row weight-0>
		<ui-card widget>
			<h2 class="mb-3"><span class="subtitle">Device not supported</span></h2>
			<p>It looks like you're using a mobile device.</p>
			<p>Mobile devices aren't supported by WeQuote at this time. We are working hard on a companion app with groundbreaking features for dealers.</p>
			<p class="mb-0">Please try again on a PC or tablet.</p>
		</ui-card>
	</ui-grid-row>
	<ui-grid-row weight-0>
		<ui-card widget>
			<a class="btn btn-block btn-primary btn-lg" (click)="skipChecks()">Continue Anyway</a>
			<a class="btn btn-block btn-secondary btn-lg" href="https://wequote.cloud">Visit Website</a>
		</ui-card>
	</ui-grid-row>
</ui-grid-col>
