<div class="notification-container">
	<div *ngFor="let item of list" class="card-box p-0 notification selectable" [ngClass]="{ on: item.shown }" (click)="itemClick(item)">
		<div class="text-light bg-{{item.style}} d-flex align-items-center p-3 rounded">
			<div class="mr-3">
				<i class="icon xl wq" [ngClass]="{ 'wq-info': item.style === 'primary' || item.style === 'info', 'wq-error': item.style === 'danger', 'wq-warning': item.style === 'warning', 'wq-check': item.style === 'success' }"></i>
			</div>
			<div class="flex-weight-1">
				<div class="text-large">
					{{item.title}}
				</div>
				<div *ngIf="item.message" class="mt-2">{{item.message}}</div>
			</div>
			<div *ngIf="item.action" class="ml-3">
				<button class="btn btn-outline-light" (click)="itemActionClick(item)">{{item.action.title}}</button>
			</div>
		</div>
	</div>
</div>
