import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'app-core-quote-forward-modal',
	templateUrl: './core-quote-forward-modal.component.html'
})
export class CoreQuoteForwardModalComponent extends UIModalBaseComponent {

	id;
	mode = 'send';

	constructor(
		public app: AppService,
		private router: Router
	) {
		super();
	}

	open(data) {
		this.id = data.id;
		this.mode = data.mode;
		this.modal.open();
	}

	openProposalGenerator() {
		// By using a link, we rely on navigation to close the modal for us. However, that doesn't work
		// if we're already on the proposal generator. Use Angular Router instead.
		this.modal.close();
		this.router.navigate(['/', this.app.org, 'quote', this.id, 'view-proposal'], { replaceUrl: true });
	}

}
