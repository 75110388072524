import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { UIGridBaseComponent } from 'app/ui/ui-grid-base.component';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
	selector: 'app-mobile-blocker',
	templateUrl: './mobile-blocker.component.html'
})
export class MobileBlockerComponent extends UIGridBaseComponent implements OnInit {

	constructor(
		public app: AppService,
		private router: Router,
		private deviceService: DeviceDetectorService
	) {
		super();
	}

	ngOnInit(): void {
		if (this.app.shouldSkipDeviceCheck || !this.deviceService.isMobile()) {
			this.skipChecks();
		}
	}

	public skipChecks() {
		this.app.shouldSkipDeviceCheck = true;
		this.router.navigate(['/'], { replaceUrl: true });
	}

}
