<form>
	<ui-modal [modalTitle]="id === 'new' ? 'Add Customer' : 'Customer Details'" size="lg">
		<ng-container *ngIf="data?.record">
			<ui-form labelWidth="10rem">
				<ui-form-control label="Name">
					<input class="form-control" [(ngModel)]="data.record.name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>
				<ui-form-control label="Account No">
					<input class="form-control w-50" [(ngModel)]="data.record.account_no" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<hr>
				<ui-form-control label="Address">
					<input class="form-control" [(ngModel)]="data.record.address_line_1" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="data.record.address_line_2" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="data.record.address_line_3" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Town">
					<input class="form-control" [(ngModel)]="data.record.posttown" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="County">
					<input class="form-control" [(ngModel)]="data.record.county" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Postcode">
					<input class="form-control w-50" [(ngModel)]="data.record.postcode" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Country">
					<input class="form-control" [(ngModel)]="data.record.country" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<hr>
				<ui-form-control label="Phone number">
					<input class="form-control w-50" [(ngModel)]="data.record.phone_number" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Email address">
					<input class="form-control w-50" [(ngModel)]="data.record.email_address" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Website URL">
					<input class="form-control" [(ngModel)]="data.record.website_url" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Notes">
					<textarea rows="5" class="form-control" [(ngModel)]="data.record.notes" [ngModelOptions]="{ standalone: true }"></textarea>
				</ui-form-control>
				<ui-form-control label="Assigned to">
					<select class="custom-select w-auto" [(ngModel)]="data.record.user_id" [ngModelOptions]="{ standalone: true }">
						<option *ngFor="let item of data.user_list" [ngValue]="item.id">{{item.name}}</option>
					</select>
				</ui-form-control>
				<hr>
				<div class="d-flex">
					<h3 class="flex-weight-1">Contacts</h3>
					<label class="custom-control custom-checkbox">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="showDeleted" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label">Show Deleted</span>
					</label>
				</div>
				<table class="table table-hover">
					<tbody>
						<ng-container *ngFor="let contact of data.record.contacts">
							<tr *ngIf="showDeleted || !contact.archived" [ngClass]="{ 'table-danger': contact.archived }">
								<td>
									<div *ngIf="contact.contact_first_name || contact.contact_surname" class="text-bold">{{contact.contact_first_name}} {{contact.contact_surname}}</div>
									<div *ngIf="contact.contact_position" class="text-secondary">{{contact.contact_position}}</div>
									<div *ngIf="contact.contact_email"><a href="mailto:{{contact.contact_email}}">{{contact.contact_email}}</a></div>
									<div *ngIf="contact.contact_mobile">{{contact.contact_mobile}}</div>
								</td>
								<td>
									<div *ngIf="contact.address_line_1">{{contact.address_line_1}}</div>
									<div *ngIf="contact.address_line_2">{{contact.address_line_2}}</div>
									<div *ngIf="contact.address_line_3">{{contact.address_line_3}}</div>
									<div *ngIf="contact.posttown">{{contact.posttown}}</div>
									<div *ngIf="contact.county">{{contact.county}}</div>
									<div *ngIf="contact.postcode">{{contact.postcode}}</div>
									<div *ngIf="contact.country">{{contact.country}}</div>
								</td>
								<td class="shrink nowrap">
									<button *ngIf="!contact.archived && contact.is_primary" type="button" class="btn btn-outline-primary btn-sm border-0 px-1" (click)="setPrimary(contact)">Primary</button>
									<button *ngIf="!contact.archived && !contact.is_primary" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="setPrimary(contact)">Secondary</button>
								</td>
								<td class="shrink nowrap">
									<button *ngIf="!contact.archived" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="editContact(contact)"><i class="wq wq-edit"></i></button>
								</td>
								<td class="shrink nowrap">
									<button *ngIf="contact.archived" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="contact.archived = 0"><i class="wq wq-undo"></i></button>
									<button *ngIf="!contact.archived" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="deleteContact(contact)"><i class="wq wq-delete"></i></button>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
				<button type="button" class="btn btn-outline-success btn-sm mr-2" (click)="addContact()"><i class="wq wq-add mr-2"></i>Add contact</button>
				<button type="button" class="btn btn-outline-secondary btn-sm mr-2" (click)="copyToContacts()"><i class="wq wq-add mr-2"></i>Copy customer Details</button>
			</ui-form>
		</ng-container>

		<button *ngIf="id !== 'new' && data && data.can_delete" type="button" class="btn btn-danger" (click)="deleteButton()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<label *ngIf="data && data.integration.zoho && !data.integration.zoho_id" class="custom-control custom-checkbox mr-4" ui-modal-footer>
			<input type="checkbox" class="custom-control-input" [(ngModel)]="data.record.zoho_send" [ngModelOptions]="{ standalone: true }">
			<span class="custom-control-label">Send to Zoho CRM <img src="assets/img/integration/zoho-crm-small.png" class="ml-1" style="height: 1em;"></span>
		</label>
		<button type="button" class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>

<modal-edit-customer-contact (close)="editContactModalClosed($event)"></modal-edit-customer-contact>
