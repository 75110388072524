import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'ui-grid-page-base',
	template: ``
})
export class UIGridBaseComponent {

	@HostBinding('class.d-flex') flexEnabled = true;
	@HostBinding('class.flex-weight-1') dynamicSize = true;
	@HostBinding('class.flex-column') isFlexColumn = true;

}
