<ui-modal modalTitle="Media Library" size="lg">

	<button *ngFor="let tab of tabs" class="btn border-0 mr-2" [ngClass]="{ 'btn-outline-secondary': selectedTab !== tab.id, 'btn-primary': selectedTab === tab.id }" (click)="selectTab(tab.id)" ui-modal-header>{{tab.description}}</button>

	<ng-container *ngIf="selectedTab === 'upload'">
		<p *ngIf="type === 'asset'" class="alert alert-info">
			<i class="wq wq-info mr-2"></i>Uploaded images will be saved in your <b>Asset Library</b>.
		</p>

		<p *ngIf="type === 'product'" class="alert alert-info">
			<i class="wq wq-info mr-2"></i>Uploaded images will be saved under <b>Product Images</b>. Images will be downscaled to a maximum size of 512 x 512 pixels.
		</p>

		<div class="image-drop scrollable d-flex flex-wrap p-2" [ngClass]="{ 'border-silver': !draggedOver, 'border-primary': draggedOver }" style="height: 540px;" (dragover)="fileDragOver($event)" (dragleave)="draggedOver = false" (drop)="fileDrop($event)">
			<p *ngIf="!newList.length" class="text-center text-secondary p-4 flex-weight-1">Drag images here to upload.</p>

			<div *ngFor="let item of newList" class="m-1 p-2 border border-light rounded shadow-sm d-flex flex-column selectable hoverable" style="width: 9.375rem; height: 15.5rem;" (click)="selectImage(item)">
				<div *ngIf="item.thumb_url" class="flex-weight-1 product-image" style="width: 100%; height: auto;" [style.backgroundImage]="'url(\'' + item.thumb_url + '\')' | safeStyle"></div>
				<div class="mt-2 text-bold" style="word-wrap: break-word;">{{item.name}}</div>
				<div class="mt-2 text-small text-secondary">{{item.datetime | mySQLDateToISO | date:'shortDate'}} {{item.datetime | mySQLDateToISO | date:'shortTime'}}</div>
			</div>
		</div>

		<form>
			<div class="d-flex mt-3 mb-3 align-items-center">
				<label class="col-form-label">Image URL</label>
				<input type="text" class="form-control flex-weight-1 ml-2" [(ngModel)]="url" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				<button type="submit" class="btn btn-success ml-2" [disabled]="disabled || !url" (click)="addImageURL()"><i class="wq wq-add mr-2"></i>Add from URL</button>
				<button type="button" class="btn btn-primary ml-2" [disabled]="disabled" (click)="browseFile()"><i class="wq wq-upload mr-2"></i>Browse</button>
			</div>
		</form>
	</ng-container>

	<ng-container *ngIf="selectedTab === 'product' || selectedTab === 'asset'">
		<div class="input-group mb-2">
			<div class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></div>
			<input type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" appDesktopFocus>
		</div>

		<div class="mb-2 scrollable d-flex flex-wrap p-2" style="height: 540px;">
			<div *ngFor="let item of list | keywords:search:['name', 'url'] | pagination:pagination" class="m-1 p-2 border border-light rounded shadow-sm d-flex flex-column selectable hoverable" style="width: 9.375rem; height: 15.5rem;" (click)="selectImage(item)">
				<div *ngIf="item.thumb_url" class="flex-weight-1 product-image" style="width: 100%; height: auto;" [style.backgroundImage]="'url(\'' + item.thumb_url + '\')' | safeStyle"></div>
				<div class="mt-2 text-bold" style="word-wrap: break-word;">{{item.name}}</div>
				<div class="mt-2 text-small text-secondary">{{item.datetime | mySQLDateToISO | date:'shortDate'}} {{item.datetime | mySQLDateToISO | date:'shortTime'}}</div>
			</div>

			<p *ngIf="!pagination.count" class="text-secondary">No items found.</p>
		</div>
		<app-pagination [pagination]="pagination"></app-pagination>
	</ng-container>

	<input type="file" class="d-none" (change)="uploadImage()" #fileInput>

	<button class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
</ui-modal>
