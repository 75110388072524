import { Component, OnChanges, Input, HostBinding } from '@angular/core';
import { AppService } from 'app/app.service';

declare const Mangler: any;

@Component({
	selector: 'ui-grid-header',
	template: `
		<div *ngIf="back" class="mr-2">
			<a class="text-secondary selectable" style="text-decoration: none; font-size: 1.375rem; margin-left: -0.1875rem;" (click)="app.back()">
				<i class="wq wq-chevron-left"></i>
			</a>
		</div>
		<div *ngIf="path" class="flex-weight-1 py-2">
			<div class="text-lines lines-1">
				<ng-container *ngFor="let p of pathList; let first = first">
					<i *ngIf="!first" class="wq wq-angle-right text-secondary"></i>
					<b>{{p}}</b>
				</ng-container>
				<span *ngIf="org" class="text-secondary"><i class="{{icon}} mx-2"></i> <b>{{app.orgInfo?.company_name}}</b></span>
			</div>
		</div>
		<div [ngClass]="{ 'flex-weight-1': !path }">
			<ng-content></ng-content>
		</div>
	`,
	styles: [':host { min-height: 2.3125rem; }']
})
export class UIGridHeaderComponent implements OnChanges {

	@Input() path;
	@Input() org = true;
	@Input() back = false;
	@Input() icon = 'wq wq-info';

	@HostBinding('class') cls = 'd-flex my-2 align-items-center no-print';

	pathList = [];

	constructor(public app: AppService) { }

	ngOnChanges() {
		if (Mangler.isArray(this.path)) {
			this.pathList = this.path;
		} else {
			this.pathList = [this.path];
		}
	}

}
