import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
	selector: '[appAutoFocus]'
})
export class AutofocusDirective implements AfterViewInit {

	private retry = 10;

	constructor(private el: ElementRef) { }

	ngAfterViewInit() {
		this.el.nativeElement['focus']?.();

		// Fallback if not attached to the DOM yet (ng-content content projection)
		if (!this.isFocused()) this.scheduleRetry();
	}

	isFocused() {
		return document.activeElement === this.el.nativeElement;
	}

	scheduleRetry() {
		this.retry -= 1;
		if (this.retry <= 0) return;

		setTimeout(() => {
			this.el.nativeElement['focus']?.();
			if (!this.isFocused()) this.scheduleRetry();
		}, 10);
	}

}
