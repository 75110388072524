import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from 'app/ui/ui.module';
import { FormsModule } from '@angular/forms';
import { MediaLibraryModalComponent } from './media-library-modal/media-library-modal.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
	declarations: [
		MediaLibraryModalComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		UIModule,
		SharedModule
	],
	exports: [
		MediaLibraryModalComponent
	]
})
export class MediaModule { }
