import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'app-alert-edit-modal',
	templateUrl: './alert-edit-modal.component.html'
})
export class AlertEditModalComponent extends UIModalBaseComponent {

	id;
	data;
	title;

	disabled = false;

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(id: any) {
		this.id = id;
		this.data = null;

		if (this.id === 'new') {
			this.api.alert.new(data => {
				this.data = data;
				this.title = 'New Alert';
				this.modal.open();
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		} else {
			this.api.alert.get(this.id, data => {
				this.data = data;
				this.title = 'Edit Alert';
				this.modal.open();
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		}

		this.modal.open();
	}

	saveButton() {
		this.disabled = true;
		this.api.alert.save(this.data, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('Alert saved.');
			this.modal.close();
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	deleteButton() {
		this.app.dialog.pop('Delete Alert', 'Are you sure you want to delete this alert?', ['Cancel', '*Delete'], button => {
			if (button === 1) {
				this.disabled = true;
				this.api.alert.delete(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Alert deleted.');
					this.modal.close();
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	editContentModalClosed(data) {
		if (data) {
			this.data.content = data.content;
		}
	}

}
