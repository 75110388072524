import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalEditCustomerComponent } from '../modal-edit-customer/modal-edit-customer.component';
import { ModalSelectCustomerComponent } from '../modal-select-customer/modal-select-customer.component';

@Component({
	selector: 'modal-edit-project',
	templateUrl: './modal-edit-project.component.html'
})
export class ModalEditProjectComponent extends UIModalBaseComponent {

	@ViewChild(ModalEditCustomerComponent) customerEditModal: ModalEditCustomerComponent;
	@ViewChild(ModalSelectCustomerComponent) selectCustomerModal: ModalSelectCustomerComponent;

	id;
	customerId;
	data;
	disabled = false;

	constructor(
		private app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.id = mdata.id || 'new';
		this.customerId = mdata.customerId || null;
		this.data = null;
		this.disabled = false;

		const success = data => {
			this.data = data;
			this.modal.open();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
		};

		if (this.id === 'new') {
			this.api.project.new(this.customerId, success, fail);
		} else {
			this.api.project.get(this.id, success, fail);
		}
	}

	deleteButton() {
		this.app.dialog.pop('Delete Project', 'Are you sure you want to delete this project?', ['Cancel', '*Delete'], button => {
			if (button === 1) {
				this.disabled = true;
				this.api.project.delete(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Project deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	saveButton() {
		if (!this.data) return;

		this.disabled = true;
		this.api.project.save(this.data.project, newId => {
			this.disabled = false;
			this.app.notifications.showSuccess('Project saved.');
			this.modal.close(newId);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	refreshCustomerName() {
		this.data.customer_name = '';
		if (!this.data.project.customer_id) return;

		this.api.customer.details(this.data.project.customer_id, data => {
			if (this.data.project.customer_id === data.record.id) {
				this.data.customer_name = data.record.name;
			}
		});

		this.app.tutorial?.validateStep(403);
	}

	addCustomer() {
		this.customerEditModal.open({ id: 'new' });
	}

	customerEditModalClosed(id) {
		if (id) {
			this.data.project.customer_id = id;
			this.refreshCustomerName();
		}
	}

	selectCustomerModalClosed(data) {
		if (!data) return;

		this.data.project.customer_id = data.id;
		this.data.customer_name = data.name;
	}

}
