<ui-modal modalTitle="Your quote has been accepted!">

	<p>It's time to add it to a project and start invoicing.</p>

	<ui-form *ngIf="projects" labelWidth="8rem">
		<ui-form-control label="Select Project">
			<div class="d-flex">
				<select class="custom-select" [(ngModel)]="selectedId">
					<option [ngValue]="null">No Project</option>
					<option *ngFor="let item of projects" [ngValue]="item.id">{{item.description}}</option>
				</select>

				<button type="button" class="btn btn-outline-success p-2 ml-1" (click)="addProject()"><i class="wq wq-add"></i></button>
			</div>
		</ui-form-control>
	</ui-form>

	<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
	<button *ngIf="selectedId" type="submit" class="btn btn-primary" (click)="setProject()" ui-modal-footer>Assign Project</button>

</ui-modal>

<modal-edit-project (close)="editProjectModalClosed($event)"></modal-edit-project>
