import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AutofocusDirective } from './autofocus.directive';
import { ChartjsComponent } from './chartjs/chartjs.component';
import { CountPipe } from './count.pipe';
import { DecimalPipe } from './decimal.pipe';
import { DesktopfocusDirective } from './desktopfocus.directive';
import { GravatarComponent } from './gravatar/gravatar.component';
import { KeywordsPipe } from './keywords.pipe';
import { MySQLDateToISOPipe } from './mysql-date-to-iso.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import { SafeStylePipe } from './safe-style.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SortcodePipe } from './sortcode.pipe';
import { PaginationPipe } from './pagination.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { ItemListPipe } from './itemlist.pipe';
import { ManglerFilterPipe } from './mangler-filter.pipe';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		DragDropModule
	],
	exports: [
		ChartjsComponent,
		GravatarComponent,
		KeywordsPipe,
		MySQLDateToISOPipe,
		SafeStylePipe,
		SafeUrlPipe,
		SafeHtmlPipe,
		NotificationsComponent,
		CountPipe,
		AutofocusDirective,
		DesktopfocusDirective,
		DecimalPipe,
		SortcodePipe,
		PaginationPipe,
		PaginationComponent,
		ItemListPipe,
		ManglerFilterPipe
	],
	declarations: [
		ChartjsComponent,
		GravatarComponent,
		KeywordsPipe,
		MySQLDateToISOPipe,
		SafeStylePipe,
		SafeUrlPipe,
		SafeHtmlPipe,
		NotificationsComponent,
		CountPipe,
		AutofocusDirective,
		DesktopfocusDirective,
		DecimalPipe,
		SortcodePipe,
		PaginationPipe,
		PaginationComponent,
		ItemListPipe,
		ManglerFilterPipe
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule
		};
	}
}
