<form>
	<ui-modal modalTitle="Mark Proposal As Accepted">
		<p>Confirm that the customer has accepted this quote.</p>

		<ui-form labelWidth="10rem">
			<ui-form-control label="Accepted date">
				<p-calendar [(ngModel)]="record.accepted_date" [ngModelOptions]="{ standalone: true }" dateFormat="dd/mm/yy" inputStyleClass="form-control w-7r" appendTo="body"></p-calendar>
			</ui-form-control>
		</ui-form>

		<ng-container *ngIf="quota && quota.cost">
			<hr>
			<p class="text-center text-secondary text-uppercase text-small text-bold">Quotes value <span [ngClass]="{ 'text-success': quota.new_spent <= quota.quota, 'text-danger': quota.new_spent > quota.quota }">{{quota.new_spent | decimal:0:0:true:'GBP'}}</span> / {{quota.quota | decimal:0:0:true:'GBP'}}</p>
			<p *ngIf="quota.new_spent > quota.quota" class="text-center text-secondary text-small">This will take you over your accepted quote value limit. You are able to accept this quote but must upgrade to accept further quotes.</p>
			<p *ngIf="quota.new_quota_date" class="text-center text-secondary text-bold text-small">New quota available {{quota.new_quota_date | mySQLDateToISO | date:'shortDate'}}</p>
		</ng-container>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-success" (click)="markAsAcceptedButton()" [disabled]="disabled" ui-modal-footer>Mark As Accepted</button>
	</ui-modal>
</form>
