import { Component } from '@angular/core';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'app-core-dialog-modal',
	templateUrl: './core-dialog-modal.component.html'
})
export class CoreDialogModalComponent extends UIModalBaseComponent {

	title = '';
	message = '';
	buttons = [];
	callback = null;
	html = false;
	size = 'md';
	wait = false;

	progressShow = false;
	progressMax = 0;
	progressValue = 0;
	progressText = '';

	constructor() {
		super();
	}

	pop(title, message, buttons, callback) {
		this.open({ title, message, buttons, callback });
	}

	popHtml(title, content, buttons, callback) {
		this.open({ title, message: content, buttons, callback, html: true });
	}

	popHtmlLarge(title, content, buttons, callback) {
		this.open({ title, message: content, buttons, callback, html: true, size: 'lg' });
	}

	popFixed(title, message, buttons, callback) {
		this.open({ title, message, buttons, callback, preventClose: true });
	}

	popHtmlFixed(title, content, buttons, callback) {
		this.open({ title, message: content, buttons, callback, html: true, preventClose: true });
	}

	popHtmlLargeFixed(title, content, buttons, callback) {
		this.open({ title, message: content, buttons, callback, html: true, size: 'lg', preventClose: true });
	}

	popWait(title, message) {
		this.open({ title, message, buttons: [], callback: null, preventClose: true, wait: true });
	}

	showProgress(value, max, text = '') {
		this.progressShow = true;
		this.progressValue = value;
		this.progressMax = value > max ? value : max;
		this.progressText = text;
	}

	open(data: any) {
		this.title = data.title || '';
		this.message = data.message || '';
		this.buttons = [];
		this.callback = data.callback || null;
		this.html = !!data.html;
		this.size = data.size || 'md';
		this.modal.easyClose = !data.preventClose;
		this.wait = !!data.wait;
		this.progressShow = false;

		const list = data.buttons || [];
		list.forEach((buttonItem, idx) => {
			const btn = {
				id: idx,
				name: '' + buttonItem,
				cls: 'btn-secondary'
			};

			if (btn.name.substring(0, 1) === '*') {
				btn.name = btn.name.substring(1);
				btn.cls = 'btn-primary';
			}

			if (btn.name.substring(0, 1) === '+') {
				btn.name = btn.name.substring(1);
				btn.cls = 'btn-success';
			}

			if (btn.name.substring(0, 1) === '-') {
				btn.name = btn.name.substring(1);
				btn.cls = 'btn-danger';
			}

			if (btn.name.substring(0, 1) === '!') {
				btn.name = btn.name.substring(1);
				btn.cls = 'btn-warning';
			}

			this.buttons.push(btn);
		});

		this.modal.open();
	}

	buttonClick(btn) {
		this.message = '';

		this.modal.close(btn.id);
		this.callback?.(btn.id);
	}

}
