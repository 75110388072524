<div *ngIf="tutorial" class="tutorial mr-5 mb-1">
	<div *ngIf="tutorial.activeStep?.info" class="d-flex align-items-center bg-purple text-light rounded p-4 mb-1">
		<div class="my-0 mr-4 h1"><i class="wq wq-info"></i></div>
		<div class="flex-weight-1">{{tutorial.activeStep?.info}}</div>
	</div>

	<div class="d-flex align-items-center selectable bg-dark text-light rounded px-4 py-3" (click)="toggleCollapsed()">
		<div class="mr-4">
			<img src="/assets/img/tutorial/tutorial-box.svg">
		</div>
		<div class="flex-weight-1">
			<h2 class="my-0">WeQuote Tour</h2>
		</div>
		<div class="text-secondary ml-4">
			<i class="wq" [ngClass]="{ 'wq-chevron-up': isCollapsed, 'wq-chevron-down': !isCollapsed }"></i>
		</div>
		<div style="position:absolute; left: 100%;">
			<button class="btn btn-dark btn-sm" style="border-top-left-radius: 0; border-bottom-left-radius: 0; line-height: 0.5rem; padding: 0.4rem 0.35rem 0.45rem 0.25rem;" (click)="cancelTutorial($event)"><i class="wq wq-close text-secondary" style="font-size: 0.5rem; line-height: 0.5rem;"></i></button>
		</div>
	</div>

	<div class="bg-primary text-light mt-1 rounded" [ngClass]="{ 'p-4': !isCollapsed, 'px-4 py-2': isCollapsed }">

		<div *ngIf="!isCollapsed" class="mb-4">
			<div class="d-flex align-items-center mb-3">
				<ng-container *ngIf="tutorial.activeJob">
					<h3 class="flex-weight-1 m-0">Progress</h3>
					<div class="d-flex flex-row">
						<span *ngFor="let job of tutorial.jobs; let index = index" class="border border-light border-2 rounded-circle mr-1 d-flex align-items-center job-number-small text-small text-bold" [ngClass]="{ 'bg-light text-primary': job.isComplete }">
							<div *ngIf="!job.isComplete" class="m-auto">{{index + 1}}</div>
							<div *ngIf="job.isComplete" class="m-auto"><i class="wq wq-check"></i></div>
							<span *ngIf="tutorial.activeJob === job" class="indicator"><i class="wq wq-caret-down"></i></span>
						</span>
					</div>
				</ng-container>
				<ng-container *ngIf="!tutorial.activeJob">
					<h3 class="flex-weight-1 m-0">Tutorial complete!</h3>
				</ng-container>
			</div>
			<div class="progress bg-dark">
				<div class="progress-bar bg-light rounded-right" [style.width.%]="tutorial.progress"></div>
			</div>
		</div>

		<ng-container *ngFor="let job of tutorial.jobs; let index = index">

			<ng-container *ngIf="tutorial.activeJob === job">

				<div *ngIf="!isCollapsed" class="d-flex align-items-center text-large text-bold my-2">
					<div class="border border-light border-2 rounded-circle mr-3 d-flex align-items-center job-number" [ngClass]="{ 'bg-light text-primary': job.isComplete }">
						<div *ngIf="!job.isComplete" class="m-auto">{{index + 1}}</div>
						<div *ngIf="job.isComplete" class="m-auto"><i class="wq wq-check"></i></div>
					</div>
					<div class="flex-weight-1" [ngClass]="{ 'job-complete': job.isComplete }">{{job.description}}</div>
				</div>

				<div *ngIf="isCollapsed" class="d-flex align-items-center my-2">
					<div class="border border-light border-2 rounded-circle mr-3 d-flex align-items-center job-number text-bold" [ngClass]="{ 'bg-light text-primary': job.isComplete }">
						<div *ngIf="!job.isComplete" class="m-auto">{{index + 1}}</div>
						<div *ngIf="job.isComplete" class="m-auto"><i class="wq wq-check"></i></div>
					</div>
					<div class="flex-weight-1">
						<div *ngIf="tutorial.activeJob" class="text-large text-bold">{{tutorial.activeJob.description}}</div>
						<div *ngIf="tutorial.activeVisibleStep">{{tutorial.activeVisibleStep.description}}</div>
					</div>
				</div>

				<ng-container *ngFor="let step of job.steps">

					<div *ngIf="!step.hidden && !isCollapsed" class="d-flex my-2">
						<div class="border border-light rounded mr-2 d-flex align-items-center step-check" [ngClass]="{ 'bg-light text-primary': step.isComplete }">
							<div><i *ngIf="step.isComplete" class="wq wq-check"></i></div>
						</div>
						<div class="flex-weight-1">{{step.description}}</div>
					</div>

				</ng-container>

			</ng-container>

		</ng-container>

		<ng-container *ngIf="!tutorial.activeJob">
			<p class="text-bold">Congratulations, you have now completed the walkthrough tutorial. We now suggest viewing the sample quotes we have created for you to gain a better understanding of how you can make the platform work for your company.</p>
			<p class="text-bold">If you wish to repeat this walkthrough at any time, click Reset Tutorial in the Settings page.</p>
			<div class="text-center">
				<button class="btn btn-outline-light" (click)="stopTutorial()">Close</button>
			</div>
		</ng-container>
	</div>
</div>

<app-tutorial-cancel-modal></app-tutorial-cancel-modal>
