<form>
	<ui-modal [modalTitle]="id === 'new' ? 'Add Project' : 'Project Details'">
		<ng-container *ngIf="data">
			<ui-form labelWidth="10rem">
				<ui-form-control label="Customer">
					<div class="d-flex">
						<input class="form-control" [(ngModel)]="data.customer_name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" readonly>
						<ng-container *ngIf="!customerId && !data.locked">
							<button type="button" class="btn btn-outline-secondary p-2 ml-1" (click)="selectCustomerModal.open()"><i class="wq wq-search"></i></button>
							<div class="dropdown dropdown-light d-inline-block ml-1">
								<button type="button" class="btn btn-outline-success p-2 dropdown-toggle" data-toggle="dropdown"><i class="wq wq-add"></i></button>
								<div class="dropdown-menu">
									<button type="button" class="dropdown-item dropdown-item-success" (click)="addCustomer()">Add Customer</button>
									<a [href]="'/' + app.org + '/customer'" target="_blank" class="dropdown-item dropdown-item-success">Go to Customers<i class="wq wq-open-new ml-2"></i></a>
								</div>
							</div>
						</ng-container>
					</div>
				</ui-form-control>
				<ui-form-control label="Description">
					<input class="form-control" [(ngModel)]="data.project.description" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>
				<ui-form-control *ngIf="id !== 'new'" label="Project #">
					<input class="form-control text-right w-30" [(ngModel)]="data.project.project_no" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<hr>
				<ui-form-control *ngIf="id === 'new' && data.currencies.length > 1" label="Currency">
					<select class="custom-select w-auto" [(ngModel)]="data.project.currency_id" [ngModelOptions]="{ standalone: true }">
						<option *ngFor="let c of data.currencies" [ngValue]="c.currency_id">{{c.currency_id}}</option>
					</select>
				</ui-form-control>
			</ui-form>
		</ng-container>

		<button *ngIf="id !== 'new' && data && !data.locked" type="button" class="btn btn-danger" (click)="deleteButton()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button type="button" class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>

<modal-edit-customer (close)="customerEditModalClosed($event)"></modal-edit-customer>
<modal-select-customer (close)="selectCustomerModalClosed($event)"></modal-select-customer>
