import { Component } from '@angular/core';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'app-core-knowledgebase-modal',
	templateUrl: './core-knowledgebase-modal.component.html'
})
export class CoreKnowledgebaseModalComponent extends UIModalBaseComponent {

	message = '';
	disabled = false;

	constructor() {
		super();
	}

	open(_data: any = null) {
		this.modal.open();
	}

}
