import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { ModalQuoteChooseProjectComponent } from 'app/modal/modal-quote-choose-project/modal-quote-choose-project.component';
import { CoreQuoteAcceptModalComponent } from '../core-quote-accept-modal/core-quote-accept-modal.component';
import { CoreQuoteForwardModalComponent } from '../core-quote-forward-modal/core-quote-forward-modal.component';
import { CoreQuoteReviewResultModalComponent } from '../core-quote-review-result-modal/core-quote-review-result-modal.component';

@Component({
	selector: 'app-core-quote-header',
	templateUrl: './core-quote-header.component.html'
})
export class CoreQuoteHeaderComponent implements OnInit, OnChanges, OnDestroy {

	@Input() quoteId;

	@ViewChild(CoreQuoteForwardModalComponent) quoteForwardModal: CoreQuoteForwardModalComponent;
	@ViewChild(CoreQuoteAcceptModalComponent) quoteAcceptModal: CoreQuoteAcceptModalComponent;
	@ViewChild(CoreQuoteReviewResultModalComponent) reviewResultModal: CoreQuoteReviewResultModalComponent;
	@ViewChild(ModalQuoteChooseProjectComponent) chooseProjectModal: ModalQuoteChooseProjectComponent;

	id;
	data;
	refreshTimer;

	editedTitle = '';
	titleEdit = false;

	disabled = false;

	private sub;

	get showReviewResult() { return this.app.orgInfo?.role.can_review_quotes && this.data?.stage === 'in_review'; }

	constructor(
		private app: AppService,
		private api: ApiService
	) { }

	ngOnInit() {
		this.sub = this.app.quotePriceChange.subscribe(() => {
			this.scheduleRefresh();
		});
	}

	ngOnChanges() {
		if (this.quoteId !== this.id) {
			// Quote ID has changed
			this.id = this.quoteId;
			this.data = null;
			this.refresh();
		}
	}

	ngOnDestroy() {
		clearTimeout(this.refreshTimer);
		this.sub.unsubscribe();
	}

	refresh() {
		clearTimeout(this.refreshTimer);
		if (!this.id) return;

		this.api.quote.headerSummary(this.id, data => {
			this.disabled = false;
			this.data = data;

			if (['accepted', 'complete'].includes(this.data.stage) && !this.data.project_id && this.data.set_project_counter < 2) {
				this.chooseProjectModal.open(this.data.customer_id);
			}
		}, () => {
			this.disabled = false;
		});
	}

	scheduleRefresh() {
		clearTimeout(this.refreshTimer);
		setTimeout(() => this.refresh(), 2000);
	}

	changeStage(s) {
		if (!this.id) return;

		this.disabled = true;
		this.api.quote.changeStage(this.id, s, () => {
			this.refresh();
			this.app.quoteStageChange.emit(s);
		}, error => {
			this.disabled = false;

			switch (error.code) {
				case 4:
				case 1:
					this.quoteForwardModal.open({ id: this.id, mode: error.code === 1 ? 'send' : 'review' });
					break;

				case 3:
					this.quoteAcceptModal.open({ quoteId: this.id });
					break;

				default:
					this.app.notifications.showDanger(error.message);
					break;
			}
		});
	}

	editTitle() {
		this.editedTitle = this.data.title;
		this.titleEdit = true;
	}

	cancelTitle() {
		this.titleEdit = false;
	}

	saveTitle() {
		this.disabled = true;
		this.api.quote.changeTitle(this.id, this.editedTitle, () => {
			this.titleEdit = false;
			this.refresh();
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	stageList() {
		if (this.app.orgInfo?.use_quote_review) {
			return ['in_progress', 'in_review', 'passed_review', 'sent', 'accepted', 'complete', 'cancelled'];
		} else {
			return ['in_progress', 'sent', 'accepted', 'complete', 'cancelled'];
		}
	}

	stageColor(stage) {
		switch (stage) {
			case 'cancelled': return 'danger';
			case 'in_progress': return 'secondary';
			case 'in_review': return 'warning';
			case 'passed_review': return 'info';
			case 'sent': return 'purple';
			case 'accepted': return 'success';
			case 'complete': return 'success';
		}
		return 'secondary';
	}

	stageDescription(stage) {
		switch (stage) {
			case 'cancelled': return 'Cancelled';
			case 'in_progress': return 'In Progress';
			case 'in_review': return 'In Review';
			case 'passed_review': return 'Passed Review';
			case 'sent': return 'Sent';
			case 'accepted': return 'Accepted';
			case 'complete': return 'Complete';
		}
		return '';
	}

	reviewResult() {
		this.reviewResultModal.open({ id: this.id });
	}

	chooseProjectModalClosed(data) {
		if (!data) {
			this.data.set_project_counter += 1;
			this.api.quote.updateProjectCounter({ id: this.id, count: this.data.set_project_counter }, null);
		} else {
			this.data.project_id = data;
			this.api.quote.setProject({ id: this.id, project_id: data }, () => {
				this.app.quoteStageChange.emit();
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		}
	}


}
