<ui-grid-col spaced>
	<ui-grid-header path="Alert" icon="wq wq-alert">
		<button *ngIf="app.orgInfo?.role.is_elevated" class="btn btn-purple" (click)="alertEditModal.open('new')"><i class="wq wq-add mr-2"></i>Create Alert</button>
		<button *ngIf="app.alertCount" class="btn btn-primary" (click)="dismissAll()"><i class="wq wq-check mr-2"></i>Dismiss All</button>
	</ui-grid-header>
	<ui-grid-row>
		<ui-grid-col weight-2>

			<ui-card widget [wait]="list" header="Alerts" [footer]="true">
				<div class="input-group" ui-card-header>
					<div class="input-group-prepend">
						<span class="input-group-text"><i class="wq wq-search"></i></span>
					</div>
					<input type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" appDesktopFocus>
				</div>

				<table *ngIf="list" class="table table-hover m-0">
					<tbody>
						<tr *ngFor="let item of list | keywords:search:['description'] | pagination:pagination">
							<td class="selectable" (click)="openAlert(item.id)">
								<span *ngIf="!item.dismissed" class="badge badge-primary float-right mt-1 ml-2">New</span>
								<div class="text-large" [ngClass]="{ 'text-bold': !item.dismissed }">{{item.description}}</div>
								<div class="text-small text-secondary">{{item.created_datetime | mySQLDateToISO | date:'shortDate'}} {{item.created_datetime | mySQLDateToISO | date:'shortTime'}}</div>
							</td>
							<ng-container *ngIf="app.orgInfo?.role.is_elevated">
								<td class="shrink nowrap">
									<button *ngIf="item.alert_type === 'wequote'" class="btn btn-outline-purple btn-sm border-0 px-1" (click)="alertEditModal.open(item.id)"><i class="wq wq-edit"></i></button>
								</td>
							</ng-container>
						</tr>
						<tr *ngIf="!pagination.count">
							<td class="text-secondary">No alerts found.</td>
						</tr>
					</tbody>
				</table>
				<app-pagination [pagination]="pagination" ui-card-footer></app-pagination>
			</ui-card>

		</ui-grid-col>
	</ui-grid-row>
</ui-grid-col>

<app-alert-edit-modal (close)="refresh(false)"></app-alert-edit-modal>
