import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialWidgetComponent } from './tutorial-widget/tutorial-widget.component';
import { TutorialDirective } from './tutorial.directive';
import { TutorialCancelModalComponent } from './tutorial-cancel-modal/tutorial-cancel-modal.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { UIModule } from 'app/ui/ui.module';



@NgModule({
	declarations: [
		TutorialWidgetComponent,
		TutorialDirective,
		TutorialCancelModalComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		UIModule
	],
	exports: [
		TutorialWidgetComponent,
		TutorialDirective
	]
})
export class TutorialModule { }
