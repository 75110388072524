<ui-modal modalTitle="Report a Bug" icon="wq wq-bug">

	<textarea class="form-control" style="min-height: 10rem;" [(ngModel)]="message" placeholder="Describe the problem you are reporting here..."></textarea>

	<div class="d-flex flex-row flex-fill" ui-modal-footer>
		<button class="btn btn-medium" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Close</button>
		<div class="flex-weight-1"></div>
		<button class="btn btn-primary" (click)="reportBug()" [disabled]="disabled" ui-modal-footer>Send</button>
	</div>

</ui-modal>
