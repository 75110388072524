import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIGridContainerComponent } from './ui-grid-container.component';
import { UIGridRowComponent } from './ui-grid-row.component';
import { UIGridColComponent } from './ui-grid-col.component';
import { UIGridBaseComponent } from './ui-grid-base.component';
import { UICardComponent } from './ui-card.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UIGridHeaderComponent } from './ui-grid-header.component';
import { UIButtonBadgeComponent } from './ui-button-badge.component';
import { UiIconFeatureComponent } from './ui-icon-feature.component';
import { UIFormComponent } from './ui-form.component';
import { UIFormControlComponent } from './ui-form-control.component';
import { UIModalComponent } from './ui-modal.component';
import { UITreeComponent, UITreeNodeComponent } from './ui-tree.component';
import { UIWaitComponent } from './ui-wait.component';
import { UIEllipsisTooltipDirective } from './ui-ellipsis-tooltip.directive';
import { UIAlertComponent } from './ui-alert.component';

@NgModule({
	declarations: [
		UIGridContainerComponent,
		UIGridRowComponent,
		UIGridColComponent,
		UIGridBaseComponent,
		UICardComponent,
		UIGridHeaderComponent,
		UIButtonBadgeComponent,
		UiIconFeatureComponent,
		UIFormComponent,
		UIFormControlComponent,
		UIModalComponent,
		UITreeComponent,
		UITreeNodeComponent,
		UIWaitComponent,
		UIEllipsisTooltipDirective,
		UIAlertComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule
	],
	exports: [
		UIGridContainerComponent,
		UIGridRowComponent,
		UIGridColComponent,
		UIGridBaseComponent,
		UICardComponent,
		UIGridHeaderComponent,
		UIButtonBadgeComponent,
		UiIconFeatureComponent,
		UIFormComponent,
		UIFormControlComponent,
		UIModalComponent,
		UITreeComponent,
		UIWaitComponent,
		UIEllipsisTooltipDirective,
		UIAlertComponent
	]
})
export class UIModule { }
