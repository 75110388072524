<form>
	<ui-modal modalTitle="Review Result">
		<p>Please select the result by clicking on one of the buttons below:</p>

		<div class="d-flex mb-3">
			<button type="button" class="btn flex-weight-1 mr-3" [ngClass]="{ 'btn-outline-success': newStage !== 'passed_review', 'btn-success': newStage === 'passed_review' }" (click)="newStage = 'passed_review'" [disabled]="disabled"><i class="wq wq-check mr-2"></i>Approve</button>
			<button type="button" class="btn flex-weight-1" [ngClass]="{ 'btn-outline-danger': newStage !== 'in_progress', 'btn-danger': newStage === 'in_progress' }" (click)="newStage = 'in_progress'" [disabled]="disabled"><i class="wq wq-close mr-2"></i>Reject</button>
		</div>

		<label class="col-form-label">Notes</label>
		<textarea rows="5" class="form-control" [(ngModel)]="notes" [ngModelOptions]="{ standalone: true }" [disabled]="disabled"></textarea>

		<div *ngIf="newStage === 'in_progress'" class="alert alert-info mt-3">
			When a quote is <b>rejected</b>, its stage will be set back to <b>In Progress</b>, sending it back to the owner to make changes. You must enter the reason for rejection in the <b>Notes</b> field above.
		</div>

		<div *ngIf="newStage === 'passed_review'" class="alert alert-info mt-3">
			When a quote is <b>accepted</b>, its stage will be set to <b>Passed Review</b>. It allows the owner to send the proposal to the customer.
		</div>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button *ngIf="newStage" type="submit" class="btn btn-primary" (click)="submitResult()" [disabled]="disabled" ui-modal-footer>Submit Result</button>
	</ui-modal>
</form>
