import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { Subscription } from 'rxjs';
import { TutorialCancelModalComponent } from '../tutorial-cancel-modal/tutorial-cancel-modal.component';

declare const Mangler: any;

class TutorialJob {
	id = 0;
	description = '';
	steps: TutorialStep[] = [];

	get isComplete() {
		for (let i = 0; i < this.steps.length; i++) {
			if (!this.steps[i].isComplete) return false;
		}
		return true;
	}

	constructor(id, description) {
		this.id = id;
		this.description = description;
	}

	setComplete() {
		this.steps.forEach(step => {
			step.isComplete = true;
		});
	}

}

class TutorialStep {
	id = 0;
	description = '';
	info = '';
	isComplete = false;
	hidden = false;
	collapse = false;

	completeCallback = null;
	incompleteCallback = null;

	constructor(id, description: string, info: string, hidden: boolean, collapse: boolean, completeCallback, incompleteCallback) {
		this.id = id;
		this.description = description;
		this.info = info;
		this.hidden = hidden;
		this.collapse = collapse;
		this.completeCallback = completeCallback;
		this.incompleteCallback = incompleteCallback;
	}

	checkComplete() {
		// Checks if the step is complete
		// If so, marks it as completed
		// Used to advance the tutorial

		if (!this.isComplete && this.completeCallback && this.completeCallback()) {
			this.isComplete = true;
			return true;
		}

		return false;
	}

	checkIncomplete() {
		// Checks if the step is incomplete
		// If so, marks it as incomplete
		// Used to fall back in the tutorial in case user navigates away from the target page
		if (this.isComplete && this.incompleteCallback && this.incompleteCallback()) {
			this.isComplete = false;
			return true;
		}

		return false;
	}
}

class Tutorial {
	app: AppService;
	api: ApiService;
	orgAlias = '';
	jobs: TutorialJob[] = [];

	get isComplete() {
		for (let i = 0; i < this.jobs.length; i++) {
			if (!this.jobs[i].isComplete) return false;
		}
		return true;
	}

	get activeJob(): TutorialJob {
		for (let i = 0; i < this.jobs.length; i++) {
			if (!this.jobs[i].isComplete) return this.jobs[i];
		}
		return null;
	}

	get activeStep(): TutorialStep {
		const job = this.activeJob;

		// Do nothing if no active job
		if (!job) return null;

		// Find the first incomplete step
		for (let i = 0; i < job.steps.length; i++) {
			if (!job.steps[i].isComplete) {
				return job.steps[i];
			}
		}

		return null;
	}

	get activeVisibleStep(): TutorialStep {
		const job = this.activeJob;

		// Do nothing if no active job
		if (!job) return null;

		// Find the first incomplete step
		for (let i = 0; i < job.steps.length; i++) {
			if (!job.steps[i].hidden && !job.steps[i].isComplete) {
				return job.steps[i];
			}
		}

		return null;
	}

	get lastCompleteStep(): TutorialStep {
		const job = this.activeJob;

		// Do nothing if no active job
		if (!job) return null;

		// Find the first complete step
		let last = null;
		for (let i = 0; i < job.steps.length; i++) {
			if (job.steps[i].isComplete) {
				last = job.steps[i];
			}
		}

		return last;
	}

	get progress() {
		let count = 0;
		let complete = 0;
		this.jobs.forEach(job => {
			count += 1;
			if (job.isComplete) complete += 1;
		});

		let p = count > 0 ? Math.round((complete / count) * 100) : 0;
		if (p < 2.5) p = 2.5;
		return p;
	}

	constructor(app: AppService, api: ApiService, orgAlias: string) {
		this.app = app;
		this.api = api;
		this.orgAlias = orgAlias;

		const mainNavigationStep = {
			id: 1,
			description: 'Go to main navigation',
			info: 'Go back to the main application by clicking on the WeQuote logo.',
			hidden: true,
			collapse: false,
			complete: () => app.routeData.navigation === 'main',
			incomplete: () => app.routeData.navigation !== 'main'
		};

		const mainOrQuoteNavigationStep = {
			id: 1,
			description: 'Go to main or quote navigation',
			info: 'Go back to the main application by clicking on the WeQuote logo.',
			hidden: true,
			collapse: false,
			complete: () => app.routeData.navigation === 'main' || app.routeData.navigation === 'quote',
			incomplete: () => app.routeData.navigation !== 'main' && app.routeData.navigation !== 'quote'
		};

		const data = [
			// {
			// 	id: 100,
			// 	description: 'Set Catalogue Discounts',
			// 	steps: [
			// 		mainNavigationStep,
			// 		{
			// 			id: 101,
			// 			description: 'Click "Catalogues"',
			// 			info: 'We recommend all new users complete this walkthrough. The average completion time is 5 minutes.',
			// 			hidden: false,
			// 			collapse: false,
			// 			complete: () => /^\/[^?\/]+\/catalogue\/browse$/.test(app.path),
			// 			incomplete: () => !/^\/[^?\/]+\/catalogue\/browse$/.test(app.path)
			// 		},
			// 		{
			// 			id: 102,
			// 			description: 'Click "Linked Catalogues"',
			// 			info: 'Linked supplier catalogues allow you to use their products in your quotes with real-time pricing.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => /^\/[^?\/]+\/catalogue\/linked$/.test(app.path),
			// 			incomplete: () => !/^\/[^?\/]+\/catalogue\/linked$/.test(app.path)
			// 		},
			// 		{
			// 			id: 103,
			// 			description: 'Select Staging Catalogue',
			// 			info: 'The staging catalogue has automatically been added to your account for during the trial and the tour.',
			// 			hidden: false,
			// 			collapse: false,
			// 			complete: () => (new RegExp('^\\/[^?\\/]+\\/catalogue\\/view\\/' + app.tutorialData.catalogueId + '$')).test(app.path),
			// 			incomplete: () => !(new RegExp('^\\/[^?\\/]+\\/catalogue\\/view\\/' + app.tutorialData.catalogueId + '$')).test(app.path)
			// 		},
			// 		{
			// 			id: 104,
			// 			description: 'Edit discounts',
			// 			info: 'The staging catalogue has automatically been added to your account for during the trial and the tour.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 105,
			// 			description: 'Enter discount value',
			// 			info: 'The staging catalogue has automatically been added to your account for during the trial and the tour.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 106,
			// 			description: 'Add a catalogue-wide discount of 10%', // Save changes
			// 			info: 'The staging catalogue has automatically been added to your account for during the trial and the tour.',
			// 			hidden: false,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		}
			// 	]
			// },
			// {
			// 	id: 200,
			// 	description: 'Set up your Systems',
			// 	steps: [
			// 		mainNavigationStep,
			// 		{
			// 			id: 206,
			// 			description: 'Click "Systems"',
			// 			info: 'Think of your systems and subsystems as the framework to which your products will attach.',
			// 			hidden: false,
			// 			collapse: false,
			// 			complete: () => /^\/[^?\/]+\/system$/.test(app.path),
			// 			incomplete: () => !/^\/[^?\/]+\/system$/.test(app.path)
			// 		},
			// 		{
			// 			id: 202,
			// 			description: 'Choose button',
			// 			info: 'Products are added to Subsystems when you are building quotes in the Quote Editor.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 203,
			// 			description: 'Dropdown selection',
			// 			info: 'Products are added to Subsystems when you are building quotes in the Quote Editor.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 204,
			// 			description: 'System selection',
			// 			info: 'Products are added to Subsystems when you are building quotes in the Quote Editor.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 205,
			// 			description: 'Add Systems from Presets. Use the dropdown then click an individual System or Subsystem.', // Save button
			// 			info: 'Products are added to Subsystems when you are building quotes in the Quote Editor.',
			// 			hidden: false,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		}
			// 	]
			// },
			// {
			// 	id: 300,
			// 	description: 'Set up a Labour Type',
			// 	steps: [
			// 		mainNavigationStep,
			// 		{
			// 			id: 201,
			// 			description: 'Click "Configure"', // Hidden
			// 			info: 'Create Labour Groups and Types by type of work or a specific role in your business.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => /^\/[^?\/]+\/configure\//.test(app.path),
			// 			incomplete: () => !/^\/[^?\/]+\/configure\//.test(app.path)
			// 		},
			// 		{
			// 			id: 301,
			// 			description: 'Click "Configure"',
			// 			info: 'Create Labour Groups and Types by type of work or a specific role in your business.',
			// 			hidden: false,
			// 			collapse: false,
			// 			complete: () => /^\/[^?\/]+\/configure\/labour$/.test(app.path),
			// 			incomplete: () => !/^\/[^?\/]+\/configure\/labour$/.test(app.path)
			// 		},
			// 		{
			// 			id: 302,
			// 			description: 'Add labour group button', // Hidden
			// 			info: 'Create Labour Groups and Types by type of work or a specific role in your business.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 303,
			// 			description: 'Add a Labour Group', // Save button
			// 			info: 'Create Labour Groups and Types by type of work or a specific role in your business.',
			// 			hidden: false,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		mainNavigationStep,
			// 		{
			// 			id: 201,
			// 			description: 'Click "Configure"', // Hidden
			// 			info: 'Create Labour Groups and Types by type of work or a specific role in your business.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => /^\/[^?\/]+\/configure\//.test(app.path),
			// 			incomplete: () => !/^\/[^?\/]+\/configure\//.test(app.path)
			// 		},
			// 		{
			// 			id: 301,
			// 			description: 'Click "Labour Types"', // Hidden
			// 			info: 'Create Labour Groups and Types by type of work or a specific role in your business.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => /^\/[^?\/]+\/configure\/labour$/.test(app.path),
			// 			incomplete: () => !/^\/[^?\/]+\/configure\/labour$/.test(app.path)
			// 		},
			// 		{
			// 			id: 304,
			// 			description: 'Add labour type buttons', // Hidden
			// 			info: 'Create Labour Groups and Types by type of work or a specific role in your business.',
			// 			hidden: true,
			// 			collapse: true,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 306,
			// 			description: 'Enter description', // Hidden
			// 			info: 'Automatically calculate your rates for this type of labour. We recommend using Hourly Cost and Margin %.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 307,
			// 			description: 'Set prices', // Hidden
			// 			info: 'Automatically calculate your rates for this type of labour. We recommend using Hourly Cost and Margin %.',
			// 			hidden: true,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		},
			// 		{
			// 			id: 305,
			// 			description: 'Add a Labour Type and Set your Rates', // Save button
			// 			info: 'Automatically calculate your rates for this type of labour. We recommend using Hourly Cost and Margin %.',
			// 			hidden: false,
			// 			collapse: false,
			// 			complete: () => false,
			// 			incomplete: () => false
			// 		}
			// 	]
			// },
			{
				id: 400,
				description: 'Create a Quote',
				steps: [
					mainNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote$/.test(app.path)
					},
					{
						id: 402,
						description: 'Click "Create New Quote"',
						info: 'This is the quoting overview. Use the tabs at the top to filter between quote statuses.',
						hidden: false,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/new/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/new/.test(app.path)
					},
					{
						id: 403,
						description: 'Select a customer from the dropdown or create a new customer',
						info: 'When you enter a quote, your navigation changes, signifying that you are working within a specific quote.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => !/^\/[^?\/]+\/quote\/new/.test(app.path)
					},
					{
						id: 404,
						description: 'Create the Quote',
						info: 'When you enter a quote, your navigation changes, signifying that you are working within a specific quote.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					}
				]
			},
			{
				id: 500,
				description: 'Build a Line Item Quote',
				steps: [
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 502,
						description: 'Quote summary', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/summary$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/summary$/.test(app.path)
					},
					{
						id: 503,
						description: 'Add product button', // Hidden
						info: 'This is your command centre for an individual quote. Access all parts of a quote with a single click.',
						hidden: true,
						collapse: true,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 504,
						description: 'Linked catalogues tab', // Hidden
						info: 'My Products means products you have added manually. Supplier products can be found under Linked Catalogues.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 505,
						description: 'Add a product from a supplier', // Select product to add
						info: 'My Products means products you have added manually. Supplier products can be found under Linked Catalogues.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 502,
						description: 'Quote summary', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/summary$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/summary$/.test(app.path)
					},
					{
						id: 506,
						description: 'Add custom product button', // Hidden
						info: 'Custom lines can be used for Labour, an extra or to quickly add a missing product.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 507,
						description: 'Enter line title', // Hidden
						info: 'Custom lines can be used for Labour, an extra or to quickly add a missing product.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 508,
						description: 'Add labour button', // Hidden
						info: 'Custom lines can be used for Labour, an extra or to quickly add a missing product.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 509,
						description: 'Select labour type', // Hidden
						info: 'Custom lines can be used for Labour, an extra or to quickly add a missing product.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 510,
						description: 'Add a custom line with labour', // Add product button
						info: 'Custom lines can be used for Labour, an extra or to quickly add a missing product.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					}
				]
			},
			{
				id: 600,
				description: 'Build an Advanced Quote',
				steps: [
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 601,
						description: 'Go to the Quote Editor',
						info: 'If you are doing a bigger job, you\'ll need to use the quote editor.',
						hidden: false,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path)
					},
					{
						id: 602,
						description: 'Add / Remove Systems button',
						info: 'Think of your systems and subsystems as the framework to which your products will attach.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 603,
						description: 'Select subsystem to add',
						info: 'Think of your systems and subsystems as the framework to which your products will attach.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 604,
						description: 'Click Add / Remove Systems and Add a Subsystem', // Update systems button on modal
						info: 'Think of your systems and subsystems as the framework to which your products will attach.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 601,
						description: 'Go to the Quote Editor',
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path)
					},
					{
						id: 605,
						description: 'Click +Group',
						info: 'Groups and Areas are an optional way to designate where systems will be installed.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 606,
						description: 'Add a Group (e.g. Ground Floor or First Floor)', // Save button
						info: 'Groups and Areas are an optional way to designate where systems will be installed.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 601,
						description: 'Go to the Quote Editor',
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path)
					},
					{
						id: 607,
						description: 'Click +Area',
						info: 'Groups and Areas are an optional way to designate where systems will be installed.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 606, // Same as add a group (same popup)
						description: 'Add an Area (e.g. Kitchen or Lounge)', // Save button
						info: 'Groups and Areas are an optional way to designate where systems will be installed.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 601,
						description: 'Go to the Quote Editor',
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path)
					},
					{
						id: 608,
						description: 'Select Subsystem',
						info: 'Subsystems are contained within Systems.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path)
					},
					{
						id: 609,
						description: 'Select area',
						info: 'Areas are contained within Groups.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path)
					},
					{
						id: 610,
						description: 'Select the Area and a Subsystem', // Both are selected
						info: '',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path)
					},
					// Same as for line item quote
					{
						id: 503,
						description: 'Add product button', // Hidden
						info: 'By default, products are added to your selected Subsystem and Area. They are also added to the toolkit. This saves for all future quotes!',
						hidden: true,
						collapse: true,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 504,
						description: 'My catalogues tab', // Hidden
						info: 'By default, products are added to your selected Subsystem and Area. They are also added to the toolkit. This saves for all future quotes!',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 505,
						description: 'Add a Product from a Linked Catalogue',
						info: 'By default, products are added to your selected Subsystem and Area. They are also added to the toolkit. This saves for all future quotes!',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 601,
						description: 'Go to the Quote Editor',
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/editor$/.test(app.path)
					},
					{
						id: 607,
						description: 'Click +Area',
						info: 'Now that the product is saved in the Toolkit, you can quickly add it to another area.',
						hidden: true,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 606, // Same as add a group (same popup)
						description: 'Add another Area', // Save button
						info: 'Now that the product is saved in the Toolkit, you can quickly add it to another area.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 611,
						description: 'Add a product from the Toolkit',
						info: 'Click on a product in the Toolkit to add it to the selected area. The number in the Toolkit shows how many of that part are in the quote.',
						hidden: false,
						collapse: true,
						complete: () => false,
						incomplete: () => false
					}
				]
			},
			{
				id: 700,
				description: 'Adjust Prices',
				steps: [
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 701,
						description: 'Click Price Adjustments',
						info: 'Adjust prices safe in the knowledge that it won’t change or break your default pricing.',
						hidden: false,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/adjustments$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/adjustments$/.test(app.path)
					},
					{
						id: 702,
						description: 'Change the Global Margin',
						info: 'You can change margins globally, for an individual part or for labour using the tabs in the upper right.',
						hidden: false,
						collapse: true,
						complete: () => false,
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/adjustments$/.test(app.path)
					},
					{
						id: 703,
						description: 'Update Prices',
						info: 'You can change margins globally, for an individual part or for labour using the tabs in the upper right.',
						hidden: false,
						collapse: true,
						complete: () => false,
						incomplete: () => false
					}
				]
			},
			{
				id: 800,
				description: 'Generate a Proposal',
				steps: [
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 801,
						description: 'Click Proposal Generator',
						info: 'Now we\'ll automatically transform your quote into a proposal.',
						hidden: false,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/proposal$/.test(app.path) || /^\/[^?\/]+\/quote\/[^?\/]+\/view-proposal$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/proposal$/.test(app.path) && !/^\/[^?\/]+\/quote\/[^?\/]+\/view-proposal$/.test(app.path)
					},
					{
						id: 802,
						description: 'Click Create Proposal',
						info: 'Templates are saved in the Template Editor section in your main dashboard. You can build these up over time and re-use them.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 803,
						description: 'Select a Proposal Template',
						info: 'Templates are saved in the Template Editor section in your main dashboard. You can build these up over time and re-use them.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					{
						id: 804,
						description: 'Click Create Proposal',
						info: 'Templates are saved in the Template Editor section in your main dashboard. You can build these up over time and re-use them.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					},
					mainOrQuoteNavigationStep,
					{
						id: 401,
						description: 'Click "Quotes"', // Hidden
						info: '',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote/.test(app.path)
					},
					{
						id: 501,
						description: 'Open a quote', // Hidden
						info: 'Select a quote in "In Progress" stage.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\//.test(app.path)
					},
					{
						id: 801,
						description: 'Click Proposal Generator',
						info: 'Now we\'ll automatically transform your quote into a proposal.',
						hidden: true,
						collapse: false,
						complete: () => /^\/[^?\/]+\/quote\/[^?\/]+\/proposal$/.test(app.path) || /^\/[^?\/]+\/quote\/[^?\/]+\/view-proposal$/.test(app.path),
						incomplete: () => !/^\/[^?\/]+\/quote\/[^?\/]+\/proposal$/.test(app.path) && !/^\/[^?\/]+\/quote\/[^?\/]+\/view-proposal$/.test(app.path)
					},
					{
						id: 805,
						description: 'Preview the Quote. Watermarks are removed after upgrading your account.',
						info: 'Downloading a quote will mark it as sent and the status will automatically be changed to sent. Once a quote is sent you will be required to make a new revision to make further changes.',
						hidden: false,
						collapse: false,
						complete: () => false,
						incomplete: () => false
					}
				]
			}
		];

		this.jobs = [];

		data.forEach(jobData => {
			const job = new TutorialJob(jobData.id, jobData.description);
			jobData.steps.forEach(stepData => {
				job.steps.push(new TutorialStep(stepData.id, stepData.description, stepData.info, stepData.hidden, stepData.collapse, stepData.complete, stepData.incomplete));
			});
			this.jobs.push(job);
		});
	}

	evaluate() {
		const job = this.activeJob;

		// Do nothing if no active job
		if (!job) {
			this.fireStepChange();
			return;
		}

		// Find the first incomplete step
		let stepIndex = -1;
		for (let i = 0; i < job.steps.length; i++) {
			if (!job.steps[i].isComplete) {
				stepIndex = i;
				break;
			}
		}

		if (stepIndex === -1) {
			// No incomplete step in the active job
			this.fireStepChange();
			return;
		}

		// Check if the first incomplete step is complete
		if (job.steps[stepIndex].checkComplete()) {
			// Step has been set to complete, loop next steps and complete them if needed
			while (true) {
				stepIndex += 1;
				if (stepIndex >= job.steps.length) break;
				if (!job.steps[stepIndex].checkComplete()) break;
			}
		} else {
			// Go backwards and check if we need to fall back.
			// This could happen for example if user navigates away from the required page.
			while (true) {
				stepIndex -= 1;
				if (stepIndex < 0) break;
				if (!job.steps[stepIndex].checkIncomplete()) break;
			}
		}

		// If job is complete, call evaluate again.
		// This will run evaluate on the next job in line.
		if (job.isComplete) {
			this.evaluate();
		} else {
			this.fireStepChange();
		}
	}

	fireStepChange() {
		const stepId = this.activeStep?.id;
		this.app.onTutorialStepChange.emit(stepId);
	}
}

@Component({
	selector: 'app-tutorial-widget',
	templateUrl: './tutorial-widget.component.html',
	styleUrls: ['./tutorial-widget.component.scss']
})
export class TutorialWidgetComponent implements OnInit, OnChanges, OnDestroy {

	@ViewChild(TutorialCancelModalComponent) cancelModal: TutorialCancelModalComponent;

	@Input() appPath = '';
	@Input() orgAlias = '';
	@Input() tutorialActive = false;

	tutorial: Tutorial = null;
	collapsed = false;
	forceCollapse = false;

	private tutorialSubscription: Subscription;

	get isCollapsed() {
		return this.forceCollapse || this.collapsed;
	}

	constructor(
		public app: AppService,
		private api: ApiService
	) { }

	ngOnInit(): void {
		let lastStepId = null;
		this.tutorialSubscription = this.app.onTutorialStepChange.subscribe(stepId => {
			if (stepId !== lastStepId) {
				lastStepId = stepId;
				this.forceCollapse = !!(this.tutorial?.activeStep?.collapse);
			}
		});
	}

	ngOnDestroy() {
		this.tutorialSubscription.unsubscribe();
	}

	ngOnChanges() {
		if (this.tutorialActive) {
			if (!this.tutorial || this.tutorial.orgAlias !== this.orgAlias) {
				// Create new tutorial
				this.tutorial = new Tutorial(this.app, this.api, this.orgAlias);
				if (Mangler.isArray(this.app.orgInfo?.tutorial_complete)) {
					this.tutorial.jobs.forEach(job => {
						if (this.app.orgInfo.tutorial_complete.indexOf(job.id) !== -1) job.setComplete();
					});
				}
			}
		} else {
			// Destroy tutorial
			this.tutorial = null;
			this.app.onTutorialStepChange.emit(null);
		}

		if (this.tutorial) {
			// Re-evaluate on path change
			const activeJob = this.tutorial.activeJob;

			this.tutorial.evaluate();

			// If active job has changed, a job must have been completed.
			// Time to update progress on the backend.
			if (activeJob !== this.tutorial.activeJob) {
				this.updateTutorial();
			}
		}
	}

	activeStepId() {
		return this.tutorial?.activeStep?.id;
	}

	validateStep(stepId) {
		if (!this.tutorial) return;

		const activeJob = this.tutorial.activeJob;

		if (!stepId) return;
		const activeStep = this.tutorial?.activeStep;
		if (!activeStep) return;
		if (activeStep.id !== stepId) return;
		if (activeStep.isComplete) return;

		activeStep.isComplete = true;
		this.tutorial.evaluate();

		// If active job has changed, a job must have been completed.
		// Time to update progress on the backend.
		if (activeJob !== this.tutorial.activeJob) {
			this.updateTutorial();
		}
	}

	invalidateStep(stepId) {
		if (!this.tutorial) return;

		const activeJob = this.tutorial.activeJob;

		if (!stepId) return;
		const activeStep = this.tutorial?.lastCompleteStep;
		if (!activeStep) return;
		if (activeStep.id !== stepId) return;
		if (!activeStep.isComplete) return;

		activeStep.isComplete = false;
		this.tutorial.evaluate();

		// If active job has changed, a job must have been completed.
		// Time to update progress on the backend.
		if (activeJob !== this.tutorial.activeJob) {
			this.updateTutorial();
		}
	}

	private updateTutorial() {
		if (!this.tutorial) return;

		// Update tutorial progress
		const jobsDone = [];
		this.tutorial.jobs.forEach(job => {
			if (job.isComplete) jobsDone.push(job.id);
		});

		// If all jobs are complete, make sure to expand tutorial widget
		if (!this.tutorial.activeJob) {
			jobsDone.push(-1); // All done
			this.collapsed = false;
		}

		// Save progress
		this.api.organisation.tutorialUpdate(jobsDone, () => { });
	}

	stopTutorial() {
		this.api.organisation.tutorialStop(() => {
			this.api.refreshUserInfo();
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	cancelTutorial(event) {
		event.stopPropagation();

		if (!this.tutorial) return;
		if (!this.tutorial.activeJob) {
			this.stopTutorial();
			return;
		}

		this.cancelModal.open();
	}

	toggleCollapsed() {
		if (this.forceCollapse) {
			this.forceCollapse = false;
			this.collapsed = false;
		} else {
			this.collapsed = !this.collapsed;
		}
	}

}
